html {
  scroll-behavior: smooth;
}

.App {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 100%; /* Ensures it covers full screen width */
}
.main-content {
  grid-column: 2 / span 10; /* Starts at column 2 and spans 10 columns */
}
.App-header {
  grid-column: 1 / -1; /* Spans all columns */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #282c34; /* Dark background for contrast */
  width: 100%; /* Ensures full width */
}

h1 {
  font-size: 5rem; /* Large text for your name */
}

h1.ProjectHeading {
  text-align: center;
  font-size: 3rem;
}

h2.Subtitle {
  font-size: 3rem;
  text-align: center;
}

h3.ProjectTitle{
  font-size: 2.5rem;
  margin: 20px 0;
}

p {
  font-size: 1.8rem; /* Smaller text for titles */
  margin-top: 20px; /* Space between name and titles */
  text-align: justify;
}

/* Navigation Bar Styles */

.navbar {
  grid-column: 1 / -1; /* Spans all columns for full width */
  display: flex;
  justify-content: center;
  padding: 15px 0;
  background-color: #333;
  position: sticky;
  top: 0;
  z-index: 1000;
  color: white;
}

.navbar a {
  color: white;
  padding: 15px 99px;
  text-decoration: none;
  font-size: 25px;
  transition: background-color 0.3s, color 0.3s;
}

.navbar a:hover {
  background-color: #555;
  color: #ddd;
}

/* Add existing styles below */

/* Responsive Design for smaller screens */

@media (max-width: 768px) {
  .main-content {
    grid-column: 1 / span 12; /* Takes full width on small screens */
  }
  .navbar {
    flex-direction: column;
  }
  .navbar a {
    text-align: center;
    padding: 10px;
    width: 100%;
  }
}
/* Existing styles... */

/* Section General Styles */
section {
  color: #333;
}

/* Projects Section Styles */
.project-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(72, 30, 30, 0.1);
  /*margin-bottom: 40px; /* Spacing between projects */
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Aligns content at the top */
  width: 100%;
  height: auto;
}
.text {
  flex: 1; /* Takes up half of the container */
  padding: 35px; /* Adds spacing between text and images */
  text-align: justify;
}
.images {
  flex: 1; /* Takes up the other half of the container */
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers the images in their container */
  padding-top: 50px;
  padding-right: 35px;
  padding-left: 20px;
}

.images img {
  width: 100%; /* Adjust based on your design requirements */
  max-width: 800px; /* Limits image width */
}

.images .centered {
  align-self: center; /* Centers the third image specifically */
}

.video {
  flex: 1; /* Takes up the other half of the container */
  max-width: 1000px; /* Optionally restrict the maximum width of the video */
}

/* Skills Section Styles */
.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.skill-item {
  background: #e7e7e7;
  padding: 10px 20px;
  margin: 10px;
  border-radius: 20px;
}

/* Contact Section Styles */
.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-details {
  margin: 10px 0;
}

@media (max-width: 768px) {
  .skills-list {
    flex-direction: column;
    align-items: center;
  }
}
